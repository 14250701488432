import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import {Button, ButtonProps, Typography, Tooltip} from '@mui/material'

export const ConnectSignalsButton = ({
  connectedSignals,
  onClick,
  ButtonProps
}: {
  connectedSignals: number
  onClick: () => void
  ButtonProps?: Omit<ButtonProps, 'variant' | 'color' | 'startIcon' | 'sx'>
}) => {
  const getButtonProps = (
    connectedSignals: number
  ): Pick<ButtonProps, 'variant' | 'color' | 'startIcon' | 'sx'> => {
    switch (true) {
      case connectedSignals > 0:
        return {variant: 'outlined', color: 'success', startIcon: <CheckIcon />}
      case connectedSignals === 0:
        return {variant: 'contained', color: 'error', startIcon: <InfoOutlinedIcon />}
      default:
        return {
          variant: 'contained',
          color: 'warning',
          startIcon: <WarningAmberIcon />,
          sx: {color: 'white'}
        }
    }
  }

  return (
    <Tooltip
      title={
        'Sensors for General Kiln need to be mapped here before they are available for assignment'
      }
    >
      <Button {...getButtonProps(connectedSignals)} onClick={onClick} {...ButtonProps}>
        <Typography noWrap fontWeight="500">
          {connectedSignals} connected
        </Typography>
      </Button>
    </Tooltip>
  )
}
