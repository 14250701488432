import {useMemo} from 'react'

import {useArrayMemo} from '../../../../shared/hooks/useArrayMemo'
import {SignalMetadata, SignalsListType} from '../../types/sensor.types'
import {StructureNode} from '../useGetPlantStructure'
import {useGetUpmSignalsMultiple} from '../useGetUpmSignals'

export const useGetSignalsMetadata = (areas: StructureNode[] | undefined) => {
  // fetch signals for all areas
  const signalQueries = useGetUpmSignalsMultiple(
    areas?.map(
      (area) =>
        ({
          entity: 'area',
          entityId: area.id
        }) as const
    ) ?? [],
    {
      enabled: areas !== undefined
    }
  )

  const queries = useArrayMemo<SignalsListType[]>(signalQueries.map((r) => r.data ?? []))

  // get signal metadata to display in list
  return useMemo(() => {
    const metadata: SignalMetadata[] = []

    queries?.forEach((query) => {
      if (query) {
        query.forEach((signal) =>
          metadata.push({
            localName: signal.localName,
            description: signal.description,
            area: signal.area.name,
            productionLine: signal.productionLine.name,
            source: signal.source,
            equipment: signal.equipment.name,
            svgId: '',
            group: signal.area.name
          })
        )
      }
    })
    return metadata
  }, [queries])
}
