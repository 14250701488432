import {DraggableAttributes, useDraggable} from '@dnd-kit/core'
import {CSS} from '@dnd-kit/utilities'
import {DrivePart} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {DriveLayout} from '@hconnect/common/components/kmsStatus'
import FlipIcon from '@mui/icons-material/Flip'
import {
  Box,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button
} from '@mui/material'
import {useSnackbar} from 'notistack'
import {Dispatch, SetStateAction} from 'react'

import {DragHandle} from '../../../../shared/components/DragHandle'

import {ConnectSignalsButton} from './ConnectSignalsButton'
import {getConnectedSignals, useConnectedSignals} from './hooks/useConnectedSignals'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'

export const Drive = ({
  positionYOffset,
  onConnectedClick,
  setSignalsDisconnectWarning
}: {
  positionYOffset: number
  onConnectedClick: () => void
  setSignalsDisconnectWarning: Dispatch<
    SetStateAction<{
      open: boolean
      signals: string[]
      action: (() => void) | null
    }>
  >
}) => {
  const kilnLayout = useKilnLayout()

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: kilnLayout.drive.id
  })

  const style = {
    transform: CSS.Translate.toString(transform) // Apply transform for dragging
  }

  return (
    <>
      <Box
        style={style}
        sx={{
          position: 'absolute',
          top: kilnLayout.drive.y + positionYOffset,
          left: kilnLayout.drive.x,
          width: kilnLayout.drive.width,
          height: kilnLayout.drive.height
        }}
      >
        <DrivePart
          id={kilnLayout.drive.id}
          x={0}
          y={0}
          width={kilnLayout.drive.width}
          height={kilnLayout.drive.height}
          position={kilnLayout.drive.position}
          // default "side" is left
          flip={
            kilnLayout.drive.side === 'right' ||
            (!kilnLayout.drive.side && kilnLayout.materialFlow !== 'rightToLeft') // this is for backwards compatibility
          }
        />
      </Box>
      <DriveOverlay
        driveLayout={kilnLayout.drive}
        style={style}
        setNodeRef={setNodeRef}
        attributes={attributes}
        listeners={listeners}
        onConnectedClick={onConnectedClick}
        setSignalsDisconnectWarning={setSignalsDisconnectWarning}
      />
    </>
  )
}

type DriveOverlayProps = {
  driveLayout: DriveLayout
  style: {
    transform: string | undefined
  }
  setNodeRef: (element: HTMLElement | null) => void
  attributes: DraggableAttributes
  listeners: any
  onConnectedClick: () => void
  setSignalsDisconnectWarning: Dispatch<
    SetStateAction<{
      open: boolean
      signals: string[]
      action: (() => void) | null
    }>
  >
}

const DriveOverlay = ({
  driveLayout,
  style,
  setNodeRef,
  attributes,
  listeners,
  onConnectedClick,
  setSignalsDisconnectWarning
}: DriveOverlayProps) => {
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const connectedSignals = useConnectedSignals({
    selectedSignals: kilnLayout.selectedSignals,
    kilnPartId: driveLayout.id
  })

  const handleToggle = (newDriveType: 'single' | 'dual') => {
    if (newDriveType === 'dual') {
      dispatch({type: LayoutActionType.SET_DUAL_DRIVE})
      return
    }

    const dispatchActions = () => {
      if (newDriveType === 'single' && kilnLayout.isRotationClockwise) {
        dispatch({type: LayoutActionType.SET_BOTTOM_DRIVE})
      } else {
        dispatch({type: LayoutActionType.SET_TOP_DRIVE})
      }
    }

    const connectedSignals = getConnectedSignals(kilnLayout.selectedSignals, driveLayout.id)

    if (connectedSignals.length > 0) {
      setSignalsDisconnectWarning({
        open: true,
        signals: connectedSignals,
        action: () => {
          dispatchActions()
          enqueueSnackbar(
            `Drive layout changed. Following signals were disconnected: ${connectedSignals.join(
              ', '
            )}`,
            {
              variant: 'warning'
            }
          )
        }
      })
    } else {
      dispatchActions()
    }
  }

  return (
    <Box
      ref={setNodeRef}
      style={style}
      sx={{
        position: 'absolute',
        border: '2px solid rgba(41, 51, 61, 0.2)',
        borderRadius: '8px',
        height: kilnLayout.interactiveOverlayHeight,
        width: 192,
        top: 0,
        left: kilnLayout.drive.x - 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '8px'
      }}
    >
      <Stack direction="row" alignItems="center">
        <DragHandle attributes={attributes} listeners={listeners} />
        <TextField
          label="Name"
          variant="filled"
          defaultValue={driveLayout?.name ?? ''}
          onChange={(e) =>
            dispatch({
              type: LayoutActionType.SET_DRIVE_NAME,
              payload: e.target.value
            })
          }
        />
      </Stack>

      <Stack spacing={1} sx={{background: '#FFF'}}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FlipIcon />}
          sx={{border: '1px solid #E8ECF0'}}
          onClick={() => dispatch({type: LayoutActionType.FLIP_MOTOR})}
        >
          Flip Layout
        </Button>
        <ConnectSignalsButton
          connectedSignals={connectedSignals.length}
          onClick={onConnectedClick}
        />
        <ToggleButtonGroup
          fullWidth
          value={driveLayout.position === 'double' ? 'dual' : 'single'}
          exclusive
          onChange={(e, newDriveType: 'single' | 'dual') =>
            newDriveType && handleToggle(newDriveType)
          }
          color="primary"
          sx={{
            height: '48px',
            background: '#F0F2F5',
            border: '1px solid #E8ECF0',
            '& .MuiToggleButtonGroup-grouped': {border: 'none !important'}
          }}
        >
          <ToggleButton fullWidth value="single" sx={{border: 'none'}}>
            <Typography>Single</Typography>
          </ToggleButton>
          <ToggleButton fullWidth value="dual" sx={{border: 'none'}}>
            <Typography>Dual</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Box>
  )
}
