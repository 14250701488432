export const signalStatuses = ['Undefined', 'Ok', 'Warning', 'Alarm'] as const
export type SignalStatus = (typeof signalStatuses)[number]

export enum ThresholdLimit {
  HIGH_HIGH = 'HighHigh',
  HIGH = 'High',
  LOW = 'Low',
  LOW_LOW = 'LowLow'
}

export type AssetPartSignal = {
  id: string
  label: string
  description: string
}

export type SignalsAssetPartMap = {
  [key: string]: AssetPartSignal[]
}

export type LastModelBreach = {
  dateTime: string
  value: number
}

export type SignalReading = {
  value?: number
  status: SignalStatus
  unit?: string
  /** Format: ISO8601 */
  recorded?: string
  limit?: ThresholdLimit
}

export interface SignalData {
  localName: string
  description?: string
  group?: string
  unit?: string
  svgId: string
  lastReading?: SignalReading
  lastModelBreach?: LastModelBreach
}

export type SensorReadings = {
  [id: string]: {
    lastReading: SignalReading | undefined
    lastModelBreach: LastModelBreach | undefined
  }
}

type HealthIndicator = {
  value?: number
  unit?: string
  recorded?: string
  status: SignalStatus
}

export type HealthIndicatorsApi = {
  feed: HealthIndicator
  speed: HealthIndicator
  ovality: HealthIndicator
  crank: HealthIndicator
}

export type MaterialFlow = 'rightToLeft' | 'leftToRight'

export type AssetPartLayout = {
  id: string
  name?: string
  x: number
  y: number
  width: number
  height: number
  showLocation?: boolean
  flip?: boolean
}

export type StationLayout = AssetPartLayout & {
  thrustRollerPosition: 'left' | 'right' | 'none'
}

export type DriveLayout = AssetPartLayout & {
  position: 'top' | 'bottom' | 'double'
  side?: 'left' | 'right' // MUST BE OPTIONAL for backward compatibility
}

export interface KilnLayout {
  materialFlow: MaterialFlow
  isRotationClockwise: boolean
  stations: StationLayout[]
  drive: DriveLayout
  kilnShell: AssetPartLayout
  referenceDimensions: {
    width: number
    height: number
  }
}

export interface KilnLayoutState extends KilnLayout {
  interactiveOverlayHeight: number
  stationOverlayWidth: number
  selectedSignals: SignalsAssetPartMap
  feedSignals: AssetPartSignal[]
  speedSignals: AssetPartSignal[]
  ovalitySignals: AssetPartSignal[]
  axialBalanceSignals: AssetPartSignal[]
  crankSignals: SignalsAssetPartMap
}

export interface KilnInfo {
  id: string
  name?: string
  isRotationClockwise: boolean
  layout: KilnLayoutState
  isStopped: boolean
}

export interface SignalMapping {
  svgId: string
  localNames: string[]
}

export interface SignalAnomaly {
  localName: string
  status: SignalStatus
  svgId: string
  value: number
}
