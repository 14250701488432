import {KilnLayoutState} from '@hconnect/common/components/kmsStatus'
import {Dispatch, createContext, useContext, useEffect, useReducer} from 'react'

import {INITIAL_LAYOUT} from './KilnConfig.constants'
import {kilnConfigReducer} from './KilnConfig.reducer'
import {AssetConfigurationDto, KilnLayoutAction, LayoutActionType} from './KilnConfig.types'

export const KilnLayoutContext = createContext<KilnLayoutState>(null as unknown as KilnLayoutState)
export const KilnLayoutDispatchContext = createContext<Dispatch<KilnLayoutAction>>(
  null as unknown as Dispatch<KilnLayoutAction>
)

export const KilnLayoutProvider = ({
  children,
  assetConfig
}: {
  children: React.ReactNode
  assetConfig?: AssetConfigurationDto
}) => {
  const [kilnLayout, dispatch] = useReducer(
    kilnConfigReducer,
    assetConfig
      ? {...INITIAL_LAYOUT, ...assetConfig.payload.layout, ...{interactiveOverlayHeight: 600}} // we override the height here as for older configs lower value was persisted
      : INITIAL_LAYOUT
  )

  //  Selected signals change in many reducer actions so useEffect guarantees we catch all changes
  useEffect(() => {
    dispatch({type: LayoutActionType.VERIFY_HEALTH_INDICATORS})
  }, [kilnLayout.selectedSignals])

  return (
    <KilnLayoutContext.Provider value={kilnLayout}>
      <KilnLayoutDispatchContext.Provider value={dispatch}>
        {children}
      </KilnLayoutDispatchContext.Provider>
    </KilnLayoutContext.Provider>
  )
}

export const useKilnLayout = () => useContext(KilnLayoutContext)
export const useKilnLayoutDispatch = () => useContext(KilnLayoutDispatchContext)
