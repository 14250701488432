// ATOMIC PARTS
import {motion} from 'framer-motion'
export const LocationPill = ({x, y, text, ...props}) => (
  <svg
    id={`location-pill-${text}`}
    x={x}
    y={y}
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform={props.flip ? 'scale(-1, 1) translate(-24, 0)' : ''}>
      <path
        d="M1 12C1 5.649 6.149.5 12.5.5S24 5.649 24 12s-5.149 11.5-11.5 11.5S1 18.351 1 12"
        fill="#F7F9FA"
      />
      <path
        d="M1 12C1 5.649 6.149.5 12.5.5S24 5.649 24 12s-5.149 11.5-11.5 11.5S1 18.351 1 12Z"
        stroke="#E8ECF0"
      />
      <text
        fontSize="12px"
        x="50%"
        y="55%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#52667A"
      >
        {text}
      </text>
    </g>
  </svg>
)

// gearbox and motor
export const BoxPart = ({id, x, y, ...props}) => (
  <svg
    id={id}
    x={x}
    y={y}
    width={48}
    height={32}
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={32} rx={2} fill="url(#paint0_linear_833_12886)" />
    <rect width={48} height={32} rx={2} fill="black" fillOpacity={0.0} className="overlay" />
    <rect x={0.5} y={0.5} width={47} height={31} rx={1.5} stroke="black" strokeOpacity={0.2} />
    <defs>
      <linearGradient
        id="paint0_linear_833_12886"
        x1={24}
        y1={0}
        x2={24}
        y2={32}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7E2E7" />
        <stop offset={1} stopColor="#BDC7CC" />
      </linearGradient>
    </defs>
  </svg>
)

// station deflection ??
// horní čudlík
export const StationDeflection = ({id, x, y, ...props}) => (
  <svg
    id={id}
    x={x}
    y={y}
    width={12}
    height={16}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={12} height={16} rx={2} fill="url(#paint0_linear_1215_13841)" />
    <rect width={12} height={16} rx={2} fill="black" fillOpacity={0.0} className="overlay" />
    <rect x={0.5} y={0.5} width={11} height={15} rx={1.5} stroke="black" strokeOpacity={0.15} />
    <defs>
      <linearGradient
        id="paint0_linear_1215_13841"
        x1={6}
        y1={1}
        x2={6}
        y2={15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B2B2B2" />
        <stop offset={1} stopColor="#989898" />
      </linearGradient>
    </defs>
  </svg>
)

export const Roller = ({id, x, y, ...props}) => (
  <svg
    id={id}
    x={x}
    y={y}
    width={44}
    height={32}
    viewBox="0 0 44 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={44} height={32} rx={2} fill="url(#paint0_linear_833_13042)" />
    <rect width={44} height={32} rx={2} fill="black" fillOpacity={0.0} className="overlay" />
    <rect x={0.5} y={0.5} width={43} height={31} rx={1.5} stroke="black" strokeOpacity={0.2} />
    <defs>
      <linearGradient
        id="paint0_linear_833_13042"
        x1={22}
        y1={0}
        x2={22}
        y2={32}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#80888C" />
        <stop offset={0.519414} stopColor="#B2BBBF" />
        <stop offset={1} stopColor="#80888C" />
      </linearGradient>
    </defs>
  </svg>
)

export const Bearing = ({id, x, ...props}) => (
  <svg
    id={id}
    x={x}
    width={34}
    height={16}
    viewBox="0 0 34 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={34} height={16} rx={2} fill="url(#paint0_linear_833_12829)" />
    <rect width={34} height={16} rx={2} fill="black" fillOpacity={0.0} className="overlay" />
    <rect x={0.5} y={0.5} width={33} height={15} rx={1.5} stroke="black" strokeOpacity={0.2} />
    <defs>
      <linearGradient
        id="paint0_linear_833_12829"
        x1={34}
        y1={0}
        x2={34}
        y2={16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#80888C" />
        <stop offset={0.519414} stopColor="#B2BBBF" />
        <stop offset={1} stopColor="#80888C" />
      </linearGradient>
    </defs>
  </svg>
)

export const StationTyre = ({id, x, ...props}) => (
  <svg
    id={id}
    x={x}
    width={40}
    height={168}
    viewBox="0 0 40 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4} y={4} width={32} height={160} rx={2} fill="url(#paint0_linear_833_12809)" />
    <rect
      x={4}
      y={4}
      width={32}
      height={160}
      rx={2}
      fill="black"
      fillOpacity={0.0}
      className="overlay"
    />
    <rect x={4.5} y={4.5} width={31} height={159} rx={1.5} stroke="black" strokeOpacity={0.3} />
    <defs>
      <linearGradient
        id="paint0_linear_833_12809"
        x1={20}
        y1={4}
        x2={20}
        y2={164}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACB8BF" />
        <stop offset={0.488163} stopColor="white" />
        <stop offset={1} stopColor="#ACB7BF" />
      </linearGradient>
    </defs>
  </svg>
)

export const ThrustRoller = ({id, x, ...props}) => (
  <svg
    id={id}
    x={x}
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#aaa)">
      <circle cx={30} cy={30} r={26} fill="#DFE6EB" />
      <circle cx={30} cy={30} r={25.5} stroke="#000" strokeOpacity={0.2} />
    </g>
    <circle cx={30} cy={30} r={16} fill="#F3F8FC" />
    <circle cx={30} cy={30} r={15.5} stroke="#000" strokeOpacity={0.2} />
    <circle
      cx={30}
      cy={30}
      r={26}
      fill="#000"
      fillOpacity={0.0}
      className="overlay"
      filter="url(#bbb)"
    />
    <defs>
      <filter
        id="aaa"
        x={0}
        y={0}
        width={60}
        height={60}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_833_12910"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_833_12910" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_833_12910" result="shape" />
      </filter>
      <filter
        id="bbb"
        x={0}
        y={0}
        width={60}
        height={60}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_833_12910"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_833_12910" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_833_12910" result="shape" />
      </filter>
    </defs>
  </svg>
)

export const Pinion = ({id, x, ...props}) => (
  <svg
    id={id}
    x={x}
    width={62}
    height={32}
    viewBox="0 0 62 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={62} height={32} rx={2} fill="url(#paint0_linear_833_12743)" />
    <rect width={62} height={32} rx={2} fill="black" fillOpacity={0.0} className="overlay" />
    <rect y={3} width={62} height={6} fill="black" fillOpacity={0.1} />
    <rect x={0.5} y={3.5} width={61} height={5} stroke="black" strokeOpacity={0.1} />
    <rect y={13} width={62} height={6} fill="black" fillOpacity={0.1} />
    <rect x={0.5} y={13.5} width={61} height={5} stroke="black" strokeOpacity={0.1} />
    <rect y={23} width={62} height={6} fill="black" fillOpacity={0.1} />
    <rect x={0.5} y={23.5} width={61} height={5} stroke="black" strokeOpacity={0.1} />
    <defs>
      <linearGradient
        id="paint0_linear_833_12743"
        x1={31}
        y1={0}
        x2={31}
        y2={32}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#80888C" />
        <stop offset={0.519414} stopColor="#B2BBBF" />
        <stop offset={1} stopColor="#80888C" />
      </linearGradient>
    </defs>
  </svg>
)

export const GirthGear = ({id, x, ...props}) => (
  <svg
    id={id}
    x={x}
    width={56}
    height={208}
    viewBox="0 0 56 208"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={4}
      y={4}
      width={48}
      height={200}
      rx={2}
      fill="url(#paint0_linear_833_12834)"
      filter="url(#filter0_d_833_12834)"
    />
    <rect
      x={4}
      y={4}
      width={48}
      height={200}
      rx={2}
      fill="black"
      fillOpacity={0.0}
      className="overlay"
      filter="url(#filter1_d_833_12834)"
    />
    <rect x={4.5} y={4.5} width={47} height={199} rx={1.5} stroke="black" strokeOpacity={0.3} />
    <rect x={4} y={11} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={11.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={23} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={23.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={35} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={35.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={47} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={47.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={59} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={59.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={71} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={71.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={83} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={83.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={95} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={95.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={107} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={107.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={119} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={119.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={131} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={131.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={143} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={143.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={155} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={155.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={167} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={167.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={179} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={179.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <rect x={4} y={191} width={48} height={6} fill="black" fillOpacity={0.1} />
    <rect x={4.5} y={191.5} width={47} height={5} stroke="black" strokeOpacity={0.1} />
    <defs>
      <filter
        id="filter0_d_833_12834"
        x={0}
        y={0}
        width={56}
        height={208}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_833_12834"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_833_12834" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_833_12834"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_833_12834"
        x={0}
        y={0}
        width={56}
        height={208}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_833_12834"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_833_12834" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_833_12834"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_833_12834"
        x1={28}
        y1={4}
        x2={28}
        y2={204}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACB8BF" />
        <stop offset={0.488163} stopColor="white" />
        <stop offset={1} stopColor="#ACB7BF" />
      </linearGradient>
    </defs>
  </svg>
)

export const MaterialFlowArrow = ({x, y, materialFlow, ...props}) => (
  <svg
    x={x}
    y={y}
    width={1100} // Adjusted width to include buffer
    height={16}
    viewBox="0 0 1100 16" // Adjusted viewBox to match the new width
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={materialFlow === 'leftToRight' ? 'scale(-1,1)' : undefined}
    style={{display: 'block', margin: '0 auto'}} // Center the SVG
    {...props}
  >
    <defs>
      <linearGradient
        id="arrowGradient"
        x1="0"
        y1="0"
        x2="1100"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#0162c4" />
        <stop offset="100%" stopColor="#808080" />
      </linearGradient>
    </defs>
    <path
      d="M0 8L10 2V6H1300V10H10V14L0 8Z" // Adjusted path to make the arrow body thinner
      fill="url(#arrowGradient)"
    />
  </svg>
)

export const MaterialFlow = ({x, y, materialFlow, text, ...props}) => (
  <svg
    x={x}
    y={y}
    width="280"
    height="55"
    viewBox="0 0 215 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <text
      x={55}
      y={0}
      fill="#54708C"
      fontSize={16}
      fontWeight={500}
      fontFamily="Inter, sans-serif"
      opacity={0.8}
    >
      {text}
    </text>
    <path
      d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H214.814V7H1V9Z"
      fill="#54708C"
      opacity={0.5}
      transform={materialFlow === 'leftToRight' ? 'scale(-1, 1) translate(-210, 0)' : undefined}
    />
  </svg>
)

export const Rotation = ({isRotationClockwise, duration, x, y}) => (
  <svg width={125} x={x} y={y} viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
    <motion.g
      initial={{rotate: 0, scaleX: isRotationClockwise ? 1 : -1}}
      animate={{rotate: 360}}
      transition={{duration: duration, repeat: Infinity, ease: 'linear'}}
    >
      <g filter="url(#a)">
        <circle cx={69} cy={69.868} r={69} fill="url(#b)" />
      </g>
      <circle cx={69} cy={69.868} r={68.5} stroke="#000" strokeOpacity={0.4} />
      <circle cx={69} cy={69.868} r={46} fill="url(#c)" />
      <circle cx={69} cy={69.868} r={45.5} stroke="#000" strokeOpacity={0.4} />
      <path
        d="M67.293 126.161a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 1 0 1.414-1.414l-5.657-5.657 5.657-5.657a1 1 0 1 0-1.414-1.414l-6.364 6.364ZM125 69.516c0 31.112-25.51 56.352-57 56.352v2c32.574 0 59-26.114 59-58.352h-2ZM85.351 15.822C108.354 23.08 125 44.382 125 69.516h2c0-26.044-17.248-48.091-41.047-55.602l-.602 1.908Zm-16.644-3.247a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 0 0-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM12 69.22c0-31.128 25.078-56.352 56-56.352v-2C35.962 10.868 10 37 10 69.22h2Zm38.958 53.696C28.365 115.661 12 94.366 12 69.22h-2c0 26.033 16.944 48.086 40.346 55.6l.612-1.904Z"
        fill="#54708C"
      ></path>
    </motion.g>
    <defs>
      <radialGradient
        id="b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(69 0 0 69 69 69.868)"
      >
        <stop stopColor="#A6A6A6" />
        <stop offset={0.519} stopColor="#E9E9E9" />
        <stop offset={1} stopColor="#8D8D8D" />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(46 0 0 46 69 69.868)"
      >
        <stop stopColor="#2C2C2C" />
        <stop offset={1} stopColor="#626262" />
      </radialGradient>
      <filter
        id="a"
        x={0}
        y={0.868}
        width={138}
        height={138}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius={2} in="SourceAlpha" result="effect1_innerShadow_1158_13362" />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1158_13362" />
      </filter>
    </defs>
  </svg>
)
