import {DraggableAttributes, useDraggable} from '@dnd-kit/core'
import {CSS} from '@dnd-kit/utilities'
import {StationPart, StationParts} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {StationLayout} from '@hconnect/common/components/kmsStatus'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FlipIcon from '@mui/icons-material/Flip'
import {Box, Button, FormControlLabel, Stack, Switch, TextField} from '@mui/material'
import {useSnackbar} from 'notistack'

import {DragHandle} from '../../../../shared/components/DragHandle'

import {ConnectSignalsButton} from './ConnectSignalsButton'
import {getConnectedSignals, useConnectedSignals} from './hooks/useConnectedSignals'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'

type StationProps = {
  station: StationLayout
  positionYOffset: number
  handleModalOpen: () => void
}

export const Station = ({station, positionYOffset, handleModalOpen}: StationProps) => {
  const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
    id: station.id
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  return (
    <>
      <Box
        style={style}
        sx={{
          position: 'absolute',
          top: station.y + positionYOffset,
          left: station.x,
          width: station.width,
          height: station.height
        }}
      >
        <StationPart
          id={station.id}
          x={0}
          y={0}
          thrustRollerPosition={station.thrustRollerPosition}
          height={station.height}
          width={station.width}
        />
      </Box>
      <StationOverlay
        handleModalOpen={handleModalOpen}
        stationLayout={station}
        style={style}
        setNodeRef={setNodeRef}
        attributes={attributes}
        listeners={listeners}
        isDragging={isDragging}
      />
    </>
  )
}

const StationOverlay = ({
  stationLayout,
  style,
  setNodeRef,
  attributes,
  listeners,
  handleModalOpen,
  isDragging
}: {
  stationLayout: StationLayout
  style: {
    transform: string | undefined
  }
  setNodeRef: (element: HTMLElement | null) => void
  attributes: DraggableAttributes
  listeners: any
  handleModalOpen: () => void
  isDragging: boolean
}) => {
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const connectedSignals = useConnectedSignals({
    selectedSignals: kilnLayout.selectedSignals,
    kilnPartId: stationLayout.id
  })

  //  toast notification about removing relevant signals
  const handleToggle = (addThrustRoller: boolean) => {
    if (!addThrustRoller) {
      const connectedSignals = getConnectedSignals(
        kilnLayout.selectedSignals,
        `${stationLayout.id}_${StationParts.THRUST_ROLLER}`
      )
      dispatch({type: LayoutActionType.REMOVE_THRUST_ROLLER, payload: {id: stationLayout.id}})
      if (connectedSignals.length === 0) return

      enqueueSnackbar(
        `Thrust roller removed. Following signals were disconnected: ${connectedSignals.join(
          ', '
        )}`,
        {
          variant: 'warning'
        }
      )
      return
    }

    dispatch({
      type: LayoutActionType.ADD_THRUST_ROLLER,
      payload: {
        id: stationLayout.id,
        position: kilnLayout.materialFlow === 'leftToRight' ? 'right' : 'left'
      }
    })
  }

  return (
    <Box
      ref={setNodeRef}
      style={style}
      sx={{
        position: 'absolute',
        border: '2px solid rgba(41, 51, 61, 0.2)',
        borderRadius: '8px',
        width: kilnLayout.stationOverlayWidth,
        height: kilnLayout.interactiveOverlayHeight,
        top: 0,
        left: stationLayout.x - (stationLayout.thrustRollerPosition === 'none' ? 57 : 37),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '8px',
        boxShadow: isDragging ? '0px 2px 32px 0px #00000014;' : 'none'
      }}
    >
      <Stack direction="row" alignItems="center">
        <DragHandle attributes={attributes} listeners={listeners} />
        <TextField
          label="Name"
          variant="filled"
          defaultValue={stationLayout?.name ?? ''}
          onChange={(e) =>
            dispatch({
              type: LayoutActionType.SET_STATION_NAME,
              payload: {id: stationLayout.id, name: e.target.value}
            })
          }
        />
      </Stack>
      <Stack spacing={1} sx={{background: '#FFF'}}>
        <FormControlLabel
          control={
            <Switch
              checked={stationLayout.thrustRollerPosition !== 'none'}
              onClick={(e: any) => handleToggle(e.target.checked)}
            />
          }
          label="Thrust Roller"
        />
        <Button
          variant="outlined"
          color="secondary"
          disabled={stationLayout.thrustRollerPosition === 'none'}
          startIcon={<FlipIcon />}
          sx={{border: '1px solid #E8ECF0'}}
          onClick={() =>
            dispatch({
              type: LayoutActionType.FLIP_THRUST_ROLLER,
              payload: {id: stationLayout.id}
            })
          }
        >
          Flip Roller
        </Button>
        <ConnectSignalsButton
          connectedSignals={connectedSignals.length}
          onClick={handleModalOpen}
        />
        <Button
          variant="outlined"
          color="error"
          onClick={() =>
            dispatch({type: LayoutActionType.REMOVE_STATION, payload: {id: stationLayout.id}})
          }
          startIcon={<DeleteOutlineIcon />}
        >
          Delete
        </Button>
      </Stack>
    </Box>
  )
}
